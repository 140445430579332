import {
  Gambar1,
  Gambar2,
  Gambar3,
  Gambar4,
  Gambar5,
  Gambar6,
  Gambar7,
  Gambar8,
  Gambar9,
} from "../asset";

export const navLink = [
  {
    id: "home",
    name: "Beranda",
  },
  {
    id: "profile",
    name: "Profil",
  },
  {
    id: "plan",
    name: "Rencana",
  },
  {
    id: "spam",
    name: "SPAM",
  },
  {
    id: "contact",
    name: "Contact Us",
  },
];

export const features = [
  {
    image: Gambar1,
    title: "Wilayah Pelayanan",
    desc1: "Kabupaten Wonogiri ",
    desc2: "Kabupaten Sukoharjo ",
    desc3: "Kota Solo ",
    desc4: "Kabupaten Karanganyar ",
  },
  {
    image: Gambar2,
    title: "Rencana Produksi",
    desc1: "Kab.Wonogiri: 200lt/dt ",
    desc2: "Kab. Sukoharjo: 500lt/dt ",
    desc3: "Kota Solo: 700lt/dt ",
    desc4: "Kab. Karanganyar: 50lt/dt ",
  },
  {
    image: Gambar3,
    title: "Target Pelayanan",
    desc1: "Kab. Wonogiri: 16000 SR ",
    desc2: "Kab. Sukoharjo: 40000 SR ",
    desc3: "Kota Solo: 56000 SR ",
    desc4: "Kab. Karanganyar: 4000 SR ",
  },
];
export const gambarBendungan = [
  {
    image: Gambar4,
  },
  {
    image: Gambar5,
  },
  {
    image: Gambar6,
  },
  {
    image: Gambar7,
  },
  {
    image: Gambar8,
  },
  {
    image: Gambar9,
  },
];
