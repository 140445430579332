import React from "react";
import AboutBackgroundImage from "../asset/peta.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container"></div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">Tentang Kami</p>
        <h1 className="primary-heading">
          PT Tirta Utama Jawa Tengah (Perseroda) adalah perusahaan yang bergerak
          di bidang pengelolaan air minum (SPAM Regional) di Jawa Tengah. Saat
          ini, PT Tirta Utama Jawa Tengah (Perseroda) mengelola tiga unit SPAM
          Regional. Pertama, SPAM Regional Bregas, melayani Kabupaten Brebes,
          Kabupaten Tegal, dan Kota Tegal. Kedua, SPAM Regional Keburejo, fokus
          pelayanannya di wilayah Kabupaten Kebumen dan Kabupaten Purworejo.
          Yang ketiga SPAM Regional Petanglong, melayani Kabupaten Pekalongan,
          Kabupaten Batang, dan Kota Pekalongan. Selain berfokus pada SPAM
          Regional, PT Tirta Utama Jawa Tengah (Perseroda) juga sedang melakukan
          perluasan pasar ke kawasan industri yaitu : Wilayah peruntukan
          industri Sayung yang berada di Kab. Demak dan Kawasan Industri Terpadu
          Batang (KITB).
        </h1>
        <div className="about-buttons-container">
          <button button className="py-2 px-8 mx-2 bg-[#808D7C] rounded-full">
            Selengkapnya
          </button>
          <button className="py-2 px-8 bg-[#808D7C] rounded-full">
            <BsFillPlayCircleFill /> Lihat Video
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
